.printTable {
  .overallprojectListTable {
    border-radius: 5px;
    background: white;
    overflow: hidden;
    page-break-after: always;

    .k-column-title {
      font-size: 8px;
      font-family: Pretendard-B;
    }
  }
}

.projectTablePrintButton {
  z-index: 1000000;
  position: absolute;
  top: -10px;
  right: 0px;
}

@media print {
  table {
    page-break-after: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  td {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
}
