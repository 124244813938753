.qualityDashboardBody {
  padding: 0;

  .qualityDashboard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .qualityDashboardLegendBox {
      width: 100%;
      padding: 16px;
      display: flex;
      justify-content: space-between;

      .qualityDashboardLegendContents {
        display: flex;
        align-items: center;
      }
    }

    .k-i-circle {
      font-size: 30px;
    }

    .qualityBlue {
      color: #00b0f0;
    }
    .qualityGreen {
      color: #70ad47;
    }
    .qualityOrange {
      color: #ffc000;
    }
    .qualityRed {
      color: #ff0000;
    }
    .qualityDashboardTableBox {
      .qualityDashboardTable {
        .k-grid-header col {
          width: 25%;
        }
        .k-grid-table col {
          width: 25%;
        }

        .k-cell-inner {
          justify-content: center;
        }
      }
    }
  }
}
