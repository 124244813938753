.vendorEvaluation {
  display: flex;
  justify-content: center;

  h5 {
    margin: 0;
  }

  .evaluation {
    margin: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 50%;

    border-radius: 6px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);

    .evaluationHeader {
      text-align: center;
    }

    .evaluationName {
      text-align: right;
    }

    .evaluationBody {
      .contentsBox {
        display: flex;
        flex-direction: column;
        align-items: center;

        .contentsTitle {
          margin-bottom: 10px;
          width: 100%;
          word-break: keep-all;
        }

        .contentsBody {
          margin-bottom: 10px;
          width: 100%;
          word-break: keep-all;
        }
      }

      .infoText {
        font-size: 0.8rem;
      }
    }

    .submitButtonBox {
      display: flex;
      justify-content: center;

      .submitButton {
        width: 50%;
      }
    }
  }
}
