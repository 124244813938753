.k-widget.k-window.k-dialog {
  // top: -50px;

  // .k-grid-header col:nth-of-type(4) {
  //   width: 13%;
  // }
  // .k-grid-table col:nth-of-type(4) {
  //   width: 13%;
  // }
}

.k-animation-container.k-animation-container-relative.k-animation-container-shown{
  z-index: 10003 !important;
}

// .k-date-tab .k-datetime-wrap{
//   z-index: 10003;
// }