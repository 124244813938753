.reportPage {
  // page: a4sheet;
  page-break-after: always;

  width: 21cm;
  min-height: 29.7cm;
  padding: 1cm;
  margin: auto;
  border-radius: 5px;
  background: white;
  // box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  .reportHeader {
    border: 2px solid black;
    height: 1.8cm;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .reportTitle {
      display: flex;
      width: 100%;
      justify-content: center;
      font-size: 18pt;
      font-weight: 900;
    }

    .reportSignBox {
      display: flex;

      .reportSignBoxLeft {
        padding: auto;
        display: flex;
        text-align: center;
        align-items: center;
        width: 0.6cm;

        border-right: 1px dashed black;
        border-left: 1px dashed black;
        height: 1.8cm;
        font-size: 12pt;

        background-color: rgb(217, 217, 217);
      }
      .reportSignBoxRight {
        display: flex;

        .reportSignBoxDetail:nth-child(2) {
          width: 1.8cm;
          padding: auto;
          border-right: 1px dashed black;
          border-left: 1px dashed black;
          height: 1.8cm;
        }
        .reportSignBoxDetail {
          width: 1.8cm;
          height: 1.8cm;

          .reportSignBoxDetailTitle {
            height: 0.6cm;
            font-size: 12pt;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1px 0;
            background-color: rgb(217, 217, 217);
          }
        }
      }
    }
  }

  .reportTargetPlan {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .reportTargetPlanBox2 {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .reportTargetPlanBox {
      border: 1px solid rgb(217, 217, 217);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;
    }
    .reportTargetPlanBox.title {
      color: black;
      background-color: rgb(242, 242, 242);
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 9pt;
      font-weight: 900;
    }
    .reportTargetPlanBox.contents {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 190px;
      padding: 5px 5px;
      font-size: 8pt;
    }

    .reportTargetPlanBox.contents2 {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 20px;
      font-size: 10pt;
    }
    .reportTargetPlanBox.arrow {
      border: none;
      display: flex;
      justify-content: center;
      padding: 5px 0px;

      .k-i-arrow-60-right {
        font-size: 30px;
      }
    }
  }

  .reportSiteInfo {
    width: 100%;
    height: 0.6cm;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    display: flex;

    .reportSiteInfoTitle {
      width: 2.4cm;
      font-size: 10pt;
      font-weight: 900;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1px 0;
      background-color: rgb(217, 217, 217);
    }

    .reportSiteInfoContent {
      width: 6cm;
      font-size: 8pt;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .reportSiteInfoContent:nth-child(6) {
      width: 3cm;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .reportIndex {
    padding-left: 0.3cm;
    height: 0.6cm;
    display: flex;
    align-items: center;
    font-size: 10pt;
    font-weight: 900;
    background-color: rgb(217, 217, 217);
  }

  .reportTable {
    table {
      border-collapse: collapse;
      border-bottom: 2px solid black;
      thead {
        height: 1.2cm;
        background-color: rgb(242, 242, 242);

        tr {
          th {
            font-size: 10pt;
            font-weight: 900;
            border: 2px solid black;
          }
          th:nth-child(1) {
            width: 2.2cm;
            border-left: none;
          }
          th:nth-child(2) {
            width: 4.65cm;
          }
          th:nth-child(3) {
            width: 4.65cm;
          }
          th:nth-child(4) {
            width: 4.65cm;
            border-right: none;
          }
          th:nth-child(5) {
            width: 4.65cm;
            border-right: none;
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid lightgray;

          td {
            height: 1.2cm;
            border-right: 2px solid black;
            text-align: center;
            font-size: 10pt;
            font-weight: 900;

            .retableBodyDetail {
              display: flex;
              justify-content: space-between;

              div {
                width: calc(100% - 1.8cm);
                text-align: center;
                font-size: 10pt;
                font-weight: 900;
                height: 1.2cm;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              div:nth-last-child(1) {
                width: 1.8cm;
              }
            }
          }

          td:nth-child(1) {
            border-left: none;
            background-color: rgb(242, 242, 242);
          }

          td:nth-last-child(1) {
            border-right: none;
          }
        }
      }
    }
  }

  .reportTable2 {
    table {
      border-collapse: collapse;
      border-bottom: 2px solid black;
      thead {
        height: 0.6cm;
        background-color: rgb(242, 242, 242);

        tr {
          th {
            font-size: 10pt;
            font-weight: 900;
            border: 2px solid black;
          }
          th:nth-child(1) {
            width: 6.9cm;
            border-left: none;
            border-right: none;
          }
          th:nth-child(2) {
            width: 6.9cm;
            border-left: none;
            border-right: none;
          }
          th:nth-child(3) {
            width: 6.9cm;
            border-left: none;
            border-right: none;
          }

          th:nth-last-child(1) {
            border-right: none;
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid lightgray;
          td {
            height: 0.6cm;
            border-right: 2px solid black;
            text-align: center;
            font-size: 10pt;
            font-weight: 900;
          }

          td:nth-child(1) {
            border-left: none;
            border-right: none;
          }

          td:nth-child(2) {
            border-left: none;
            border-right: none;
          }

          td:nth-child(3) {
            border-left: none;
            border-right: none;
          }

          td:nth-last-child(1) {
            border-right: none;
          }
        }
      }
    }
  }

  .reportChartBox {
    display: flex;
    flex-wrap: wrap;

    .reportChartBoxDetail {
      display: flex;
      flex-direction: column;
      height: 7cm;
      width: calc(50% - 2px);

      .reportChartTitle {
        width: 50%;
        height: 0.6cm;
        background-color: rgb(242, 242, 242);
        padding-left: 0.3cm;
        font-size: 10pt;
        font-weight: 900;
        display: flex;
        align-items: center;
      }

      .reportChart {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  .reportChartBoxDetail.detail1 {
    width: calc(45% - 2px);
    margin-bottom: 10px;
  }
  .reportChartBoxDetail.detail2 {
    width: calc(55% - 2px);
    margin-bottom: 10px;

    .k-card-body {
      padding: 8px;
    }
  }

  .reportComment {
    border-top: 2px solid black;
    border-bottom: 2px solid black;

    .reportCommentBox {
      height: 1.6cm;
      border-bottom: 2px solid black;
      display: flex;

      .reportCommentTitle {
        width: 138px;
        height: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        border-right: 2px solid black;
        font-size: 10pt;
        font-weight: 900;
        color: white;
      }

      .reportUserComment {
        padding: 2px;
        font-size: 10pt;
        width: calc(100% - 140px);
        white-space: pre;
        font-size: 10pt;
      }
    }

    .reportCommentBox:nth-child(2) {
      height: 1.8cm;
      border-bottom: none;
    }
  }

  .k-grid th {
    padding: 8px 6px;
    // background-color: rgb(217, 217, 217);
  }

  .k-grid td {
    padding: 8px 6px;
    height: 0.6cm;
  }

  .k-grid tbody tr:nth-last-child(1) {
    td {
      padding: 8px 6px;
      height: 0.6cm;
      font-size: 0.9rem;
      font-weight: 900;
      background-color: rgb(217, 217, 217);
    }
  }
}
.subpage {
  padding: 1cm;
  height: 256mm;
}

.reportBlank {
  height: 0.3cm;
}

.reportPage + .reportPage {
  margin-top: 5px;
}

@media print {
  .reportPage + .reportPage {
    margin-top: 5px;
  }
}

@media print {
  table {
    page-break-after: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  td {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
}
