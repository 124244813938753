.vendorEvaluationRouter {
  width: 50%;
  display: flex;
  flex-direction: column;

  .inspectionVendorBox {
    input {
      width: calc(100% - 39.06px);
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  button {
    margin: 10px 0;
  }
}
