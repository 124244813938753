skoneEMSProgress {
  .k-tabstrip-content,
  .k-tabstrip > .k-content {
    padding: 0;
  }

  .tabstrip {
    z-index: 0;
  }
  .tabstripcontent {
    height: 700px;
  }

  .k-card-title {
    font-size: 1rem;
  }

  .k-grid table {
    font-size: 1rem;
  }

  .hovertext:hover {
    cursor: pointer;
    color: #f37321;
  }

  .k-card-header {
    font-weight: bolder;
    font-size: 1.1rem;
  }

  @media (min-resolution: 120dpi) {
    html {
      font-size: 70%;
    }

    .qcardlayout {
      padding: 5px;
      margin-top: 45px;
    }
  }

  .k-grid th,
  .k-grid td {
    padding: 4px 6px;
  }

  .course {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    /* display: flex; */
    width: 100%;
    height: 100%;
    /* margin: 20px; */
    /* overflow: hidden; */
  }

  /* 
table {
border-collapse: collapse;
}
th, td {
border-bottom: 1px solid lightgray;
border-left: 1px solid lightgray;
padding: 2px;
}
th:first-child, td:first-child {
border-left: none;
} */

  /* @font-face {
  font-family: "CrimsonText-Semi";
  src: url("./fonts/CrimsonText-SemiBold.ttf") format("ttf");
  font-display: auto;
}

@font-face {
  font-family: "CrimsonText-R";
  src: url("./fonts/CrimsonText-Regular.woff") format("woff");
  font-display: auto;
}

@font-face {
  font-family: "GowunDodum";
  src: url("./fonts/GowunDodum-Regular.woff") format("woff");
  font-display: auto;
}

@font-face {
  font-family: "NotoSansKR";
  src: url("./fonts/NotoSansKR-Light.otf") format("opentype");
  font-display: auto;
}

@font-face {
  font-family: "KoPub";
  src: url("./fonts/KoPubDotumProLight.otf") format("opentype");
  font-display: auto;
}

@font-face {
  font-family: "Pretendard-R";
  src: url("./fonts/Pretendard-Regular.woff2") format("woff2");
  font-display: auto;
}

@font-face {
  font-family: "Pretendard-B";
  src: url("./fonts/Pretendard-Bold.woff2") format("woff2");
  font-display: auto;
} */

  body {
    font-family: "Pretendard-R" !important;
  }

  .MuiPaper-rounded {
    border-radius: 15px !important;
    padding: 2% !important;
  }

  .bordernon {
    border-bottom: none !important;
  }

  .thermometer--normal .thermometer__draw-b:after {
    position: absolute;
    top: 8px;
    right: 0;
    bottom: initial;
    left: 0;
    width: 17px !important;
    height: 17px !important;
  }

  .thermometer--normal .thermometer__draw-a:after {
    position: absolute;
    top: initial;
    right: initial;
    bottom: -7px !important;
    left: -5px !important;
    width: 40px !important;
    height: 40px !important;
  }

  .datepick {
    width: 200px;
    position: relative;
    float: right;
    z-index: 100;
    right: calc(2.5% - 200px);
  }

  .middlediv {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .displayblock {
    display: block !important;
  }

  .activity .k-grid-header col:nth-of-type(1) {
    width: 10%;
  }
  .activity .k-grid-table col:nth-of-type(1) {
    width: 10%;
  }
  .activity .k-grid-header col:nth-of-type(2) {
    width: 25%;
  }
  .activity .k-grid-table col:nth-of-type(2) {
    width: 25%;
  }

  .mancount .k-grid-header col:nth-of-type(1) {
    width: 60%;
  }
  .mancount .k-grid-table col:nth-of-type(1) {
    width: 60%;
  }
  .mancount .k-grid-header col:nth-of-type(2) {
    width: 40%;
  }
  .mancount .k-grid-table col:nth-of-type(2) {
    width: 40%;
  }

  .progress .k-grid-header col:nth-of-type(1) {
    width: 20%;
  }
  .progress .k-grid-table col:nth-of-type(1) {
    width: 20%;
  }
  .progress .k-grid-footer col:nth-of-type(1) {
    width: 20%;
  }

  .ltir .k-card-body {
    padding: 8px 8px;
  }

  .punch .k-grid-header col:nth-of-type(1) {
    width: 60%;
    text-align: center;
  }
  .punch .k-grid-table col:nth-of-type(1) {
    width: 60%;
  }
  .punch .k-grid-footer col:nth-of-type(1) {
    width: 60%;
  }

  .punch thead {
    text-align: center !important;
  }

  .safety2 .k-grid-header col:nth-of-type(1) {
    width: 18%;
  }
  .safety2 .k-grid-table col:nth-of-type(1) {
    width: 18%;
  }
  .safety2 .k-grid-footer col:nth-of-type(1) {
    width: 18%;
  }

  .safety2 .k-grid-header col:nth-of-type(2) {
    width: 70%;
  }
  .safety2 .k-grid-table col:nth-of-type(2) {
    width: 70%;
  }
  .safety2 .k-grid-footer col:nth-of-type(2) {
    width: 70%;
  }

  .safety2 .k-grid-header col:nth-of-type(3) {
    width: 12%;
  }
  .safety2 .k-grid-table col:nth-of-type(3) {
    width: 12%;
  }
  .safety2 .k-grid-footer col:nth-of-type(3) {
    width: 12%;
  }

  .ment li,
  p {
    position: relative;
  }
  .ment img {
    position: absolute;
    left: 0;
    bottom: 0;
    max-width: 200px;
    object-fit: contain;
    transform: translateY(100%);
  }

  .richimag p,
  img {
    max-width: inherit;
  }
  .swiper-zoom-container img {
    z-index: 999 !important;
  }

  .mySwiper .swiper {
    width: 100%;
    height: 100%;
  }

  .mySwiper .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .mySwiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* 
.swiper {
width: 100%;
height: 300px;
margin-left: auto;
margin-right: auto;
}

.swiper-slide {
background-size: cover;
background-position: center;
} */

  .mySwiper2 {
    height: 80%;
    width: 100%;
  }

  .mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
  }

  .mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }

  .mySwiper .swiper-slide-thumb-active {
    opacity: 1;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .k-tilelayout-item-header.k-card-header {
    color: #ffffff;
    font-size: 16px;
    background: #7f84ab 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    font-family: "Pretendard-B";
  }

  .listleft .MuiListItemText-primary {
    font-family: "Pretendard-B";
    font-size: 14px;
    color: #707070;
  }

  .listright .MuiListItemText-primary {
    font-family: "Pretendard-R";
    font-size: 14px;
    color: #707070;
  }

  text {
    font-family: "Pretendard-R" !important;
  }

  .k-picker-solid {
    background-color: white;
    background-image: none;
  }

  .k-button-solid-base {
    background-color: white;
    background-image: none;
  }

  .disc .k-input-button {
    border-inline-start-width: 0px;
  }
}
