// *{
//   box-sizing: border-box;
// }

.permitTable {
  width: 100%;

  .permitTableNormalBox {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    .permitTableHeader {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 10px;
      width: 40%;
      border-right: 1px solid rgba(0, 0, 0, 0.2);
      word-break: break-all;
      font: normal normal bold 0.8rem Pretendard !important;
    }
    .permitTableContents {
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      width: 60%;
      padding: 10px;
      min-height: 50px;
      word-break: break-all;
      font: normal normal normal 0.8rem Pretendard !important;
    }
  }

  .permitTableMergeBox {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    .permitTableMergeHeader {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 10px;
      width: 20%;
      border-right: 1px solid rgba(0, 0, 0, 0.2);
      word-break: break-all;
      font: normal normal bold 0.8rem Pretendard !important;
    }

    .permitTableHeader2 {
      display: flex;
      flex-direction: column;
      width: 20%;
      border-right: 1px solid rgba(0, 0, 0, 0.2);
      word-break: break-all;
      font: normal normal bold 0.8rem Pretendard !important;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 50px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }

      div:last-child {
        border-bottom: none;
      }
    }

    .permitTableContents2 {
      display: flex;
      width: 60%;
      flex-direction: column;
      word-break: break-all;

      div {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10px;
        min-height: 50px;
        font: normal normal normal 0.8rem Pretendard !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }

      div:last-child {
        border-bottom: none;
      }
    }
  }
}

.permitMilestoneTableHeader {
  background: #7f84ab 0 0 no-repeat padding-box;
}
