.sCurveTable {
  border: 1px solid rgba(0, 0, 0, 0.08);
  overflow: scroll;

  .sCurveTableHeader {
    display: flex;
    align-items: center;
    height: 40px;
    font-weight: bold;
    background-color: #fafafa;

    .sCurveTableHeaderItem {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 1px solid rgba(0, 0, 0, 0.08);
    }
  }

  .sCurveTableBody {
    .sCurveTableBodyCol {
      display: flex;
      align-items: center;
      height: 40px;

      .sCurveTableBodyItem {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0.08);
      }
    }
  }
}

.sCurveTable {
  .k-grouping-header.k-grouping-header-flex {
    display: none !important;
  }

  .k-grid-header .k-cell-inner {
    font-weight: bold;
    justify-content: center;
  }

  .k-grid-header col:nth-of-type(1) {
    width: 10%;
  }
  .k-grid-table col:nth-of-type(1) {
    width: 10%;
  }
  .k-grid-footer col:nth-of-type(1) {
    width: 10%;
  }
}
