.blockInsert {
  position: relative;
  display: flex;
  // flex-wrap: wrap;

  .diagram-component {
    // padding: 5px;
    width: 100%;
    height: 100%;
    flex-grow: 1;
  }



  .overview-component {
    z-index: 99;
    position: absolute;
    right: 0;
    top: 0;
    width: 12.5%;
    height: 12.5%;
    background-color: rgb(30, 90, 150);
    
  }

  .controlButtonBox {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 10px 5px;
    z-index: 100;

    button {
      padding: 5px 10px;
    }
  }
}

.blockInsert.close {
  display: none;
}

button {
  margin-right: 10px;
}

.updateProgressBox {
  .updateProgressResult {
    margin-top: 10px;
  }

  .updateProgressTableBox {
    .updateProgressTable {
      display: flex;
      justify-content: space-around;
      width: 100%;

      .updateProgressFirst {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10%;
        padding: 5px 0;
      }

      .updateProgressSecond {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 45%;
        padding: 5px 0;
      }

      .updateProgressThrid {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45%;
        padding: 5px 0;
      }
    }
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
