.eMSOverallTable {
  font-size: 0.8rem;

  .k-grid-header col:nth-child(1) {
    width: 15%;
  }

  .k-grid-container col:nth-child(1) {
    width: 15%;
  }

  .k-grid-footer col:nth-child(1) {
    width: 15%;
  }
}
