.progressCWPTableBody {
  padding: 0 !important;
  height: 100%;
}

.progressCWP table {
  text-align: left;
  font: normal normal normal 0.8rem Pretendard !important;
  letter-spacing: 0px;
  color: #818181;
  opacity: 1;
  background-color: white;
}

.progressCWP table td {
  background-color: white;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.progressCWP table th {
  background-color: white;
  border: none;
  border-bottom: 1.5px solid #707070;
}

.progressCWP .k-grid-header {
  background-color: white;
}

.cellMergeHeader {
  border: 0 !important;
}
