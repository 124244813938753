.blockView {
  position: relative;
  display: flex;
  // flex-wrap: wrap;
  width: 100%;
  // height: 700px;

  .diagram-component {
    // width: 80%;
    height: 100%;
    flex-grow: 1;
    
  }

  .overview-component {
    z-index: 99;
    position: absolute;
    right: 0;
    top: 0;
    width: 12.5%;
    height: 12.5%;
    background-color: rgb(30, 90, 150);
  }

  .controlButtonBox {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 10px 5px;
    z-index: 100;

    button {
      padding: 5px 10px;
    }
  }
}
