.blockInsertModal {
  margin: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .blockInsertModalSelect {
    width: 100%;
  }

  .blockDateBox {
    display: flex;
    justify-content: space-between;
  }

  .blockInsertModalInsertBox {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .blockInsertModalSample {
      margin: 20px 0;
      border: 2px solid black;
      border-radius: 5px;
      width: 175px;

      .lockInsertModalSampleKey {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid black;
        height: 25px;
      }

      .blockInsertModalSampleTitle {
        text-align: center;
        margin: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 125px;
        word-break: break-all;
      }

      .blockInsertModalSampleDate {
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        font-weight: bold;
      }

      .blockInsertModalSampleDateBox {
        display: flex;

        .blockInsertModalSampleBoxDate {
          width: 120px;
          border-right: 1px solid black;

          div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 25px;
          }
        }

        .blockInsertModalSampleDateBoxDuration {
          width: 55px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .blockInsertModalSampleDateTop {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
      }

      .blockInsertModalSampleLogic {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid black;
        height: 50px;
      }
    }
  }
}
