@font-face {
  font-family: "CrimsonText-Semi";
  src: url("./fonts/CrimsonText-SemiBold.ttf") format("ttf");
  font-display: auto;
}

@font-face {
  font-family: "CrimsonText-R";
  src: url("./fonts/CrimsonText-Regular.woff") format("woff");
  font-display: auto;
}

@font-face {
  font-family: "GowunDodum";
  src: url("./fonts/GowunDodum-Regular.woff") format("woff");
  font-display: auto;
}

@font-face {
  font-family: "NotoSansKR";
  src: url("./fonts/NotoSansKR-Light.otf") format("opentype");
  font-display: auto;
}

@font-face {
  font-family: "KoPub";
  src: url("./fonts/KoPubDotumProLight.otf") format("opentype");
  font-display: auto;
}

@font-face {
  font-family: "Pretendard-R";
  src: url("./fonts/Pretendard-Regular.woff2") format("woff2");
  font-display: auto;
}

@font-face {
  font-family: "Pretendard-B";
  src: url("./fonts/Pretendard-Bold.woff2") format("woff2");
  font-display: auto;
}

* {
  margin: 0;
  padding: 0;
}

.tileHeaderTitle {
  font-size: 1rem;
  font-weight: bolder;
}

.infoButton {
  width: 100%;
}

.manbDetailChart {
  .k-grid .k-header {
    display: none;
  }
}

.projectTargetPlan {
  height: 100%;
  display: flex;
  align-items: center;

  .projectTargetPlanBox {
    border: 1px solid black;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }
  .projectTargetPlanBox.title {
    color: white;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .projectTargetPlanBox.contents {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .projectTargetPlanBox.arrow {
    border: none;
    display: flex;
    justify-content: center;
  }
}

.girdBlockTitle {
  font-size: 1rem;
  font-weight: 900;
  color: white;
  background-color: rgb(48, 84, 150);
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: 200px;
  height: 20px;
}

.projectInfoPage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 10px;

  .projectInfoLeft {
    display: flex;

    .projectInfoLeftContents {
      border: 1px solid #fafafa;
      margin-right: 10px;

      font-size: 0.8rem;
      font-family: Pretendard-B;
    }
  }
}

.k-tilelayout-item-header.k-card-header {
  // background-color: #7f84ab;
  background-color: rgba(24, 12, 2, 0.6);
  opacity: 0.1;
  box-shadow: 0 3px 6px #00000029;
  color: #fff;
  font-family: Pretendard-B;
  font-size: 0.9rem;
  opacity: 1;
}

.infoTextBox {
  display: flex;
  border: 1.5px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  position: relative;
  height: 30px;

  .infoTextTitle {
    display: flex;
    width: 60px;
    justify-content: center;
    align-items: center;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    font-weight: bold;
  }
  .infoTextContent {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 0.8rem;
  }

  .infoTextContent1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    width: 50px;
  }

  .infoTextContent2 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    width: 100px;
  }

  .infoTextContentCal {
    position: fixed;
    top: 30px;
    left: 0px;
    z-index: auto;
  }
}

.projectInfoButton {
  border: 1.5px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  height: 30px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.overallMonthIncreaseBox {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;

  .overallMonthIncrease {
    // height: 100%;
    // width: 100%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // border: 1px solid black;
    // border-radius: 10px;
    // writing-mode: vertical-lr;
    // background-color: rgb(48, 84, 150);
    // color: #fff;
    // font-family: Pretendard-B;
    // font-size: 0.9rem;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Pretendard-B;
    font-size: 0.9rem;
  }
}

.OverallTable {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-break: break-all;
  padding: 5px;
  border: 1px solid #e8e7e6;
  font-size: 0.7rem;
}

.OverallTable.tablegray {
  background-color: #d1cecc;
  font-family: Pretendard-B;
  border: 1px solid #ffffff;
}

.OverallTable2 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-break: break-all;
  padding: 5px;
  border: 1px solid #e8e7e6;
  font-size: 0.5rem;
}

.OverallTable2.tablegray {
  background-color: #d1cecc;
  font-family: Pretendard-B;
  border: 1px solid #ffffff;
}

.projectDetailPage {
  .k-tilelayout-item-body.k-card-body {
    overflow: hidden !important;
  }
}

.weightCostChange {
  position: relative;

  .weightCostChangeButton {
    position: absolute;
    right: -10px;
    top: -55px;
    z-index: 9999999;
  }
  .weightCostChangeButton2 {
    position: absolute;
    right: 30px;
    top: -55px;
    z-index: 9999999;
  }
}

.chartTooltipText {
  cursor: pointer;
  display: flex;
  text-decoration: underline;
  transition: all ease-in-out 0.2s;
}

.chartTooltipText:hover {
  color: blue;
  font-family: Pretendard-R;
  font-weight: 900;
  transition: all ease-in-out 0.2s;
}
